import gsap from 'gsap';
import isMobile from 'ismobilejs';
import Splitting from 'splitting';

import './functions/animations.js';

let mouse = {};
window.ismobile = isMobile().phone || window.innerWidth < 1024;

//Cards Size Init
const cards = document.querySelectorAll('.person--card');
const cardsSize = () => {
  Object.values(cards).map(card => {
    gsap.set(card, {
      width: `${(card.dataset.size * 100) / 1440}vw`,
    });
  });
};

//Scroll Section Navigation
const sections = document.querySelectorAll('[data-section-scroll]');
const navItems = document.querySelectorAll('[data-section-scroll-nav]');

const scrollSection = () => {
  Object.values(sections).map(section => {
    const sectionRect = section.getBoundingClientRect();

    if (sectionRect.top <= window.innerHeight / 2) {
      Object.values(navItems).map(item => item.classList.remove('active'));
      navItems[section.dataset.sectionScroll].classList.add('active');
    }
  });
};

const scrollSectionClick = () => {
  Object.values(navItems).map(item => {
    item.addEventListener('click', () => {
      window.scrollTo({
        top: window.scrollY + sections[item.dataset.sectionScrollNav].getBoundingClientRect().top - mainHeader.getBoundingClientRect().height,
        left: 0,
        behavior: 'smooth',
      });
    });
  });
};

const mainHeader = document.querySelector('header.main-header');

let lastScrollTop = 0;
const darkPage = document.body.classList.contains('dark');
const titleTriangle = document.querySelector('.title--triangle, .section--join .triangle--svg ');

const followButtons = document.querySelectorAll('.button--follow');

const hideButton = (item, closest) => {
  item.classList.remove('hover');

  gsap.to(item, .3, {
    left: closest.getBoundingClientRect().left,
    top: closest.getBoundingClientRect().top,
    onComplete: () => {
      gsap.set([item, closest], {clearProps: 'all'});
    },
  });
};

Object.values(followButtons).map(item => {

  if (!window.ismobile) {
    const closest = item.closest('div');

    const parent = item.closest('.media-video, .media-image, article');//.getBoundingClientRect();

    parent.addEventListener('mouseenter', () => {
      item.classList.add('hover');

      parent.dispatchEvent(new Event('mousemove'));

      gsap.set(closest, {
        width: item.getBoundingClientRect().width,
      });
    }, {passive: true});

    parent.addEventListener('mouseleave', (e) => hideButton(item, closest), {passive: true});

    ['mousemove', 'mousewheel'].map(event =>
      parent.addEventListener(event, (e) => {

        const buttonRect = item.getBoundingClientRect();

        if (item.classList.contains('hover')) {
          gsap.set(item, {
            pointerEvents: 'none',
            position: 'fixed',
            left: mouse.x - (buttonRect.width / 2),
            top: mouse.y - (buttonRect.height / 2),
            right: 'initial',
          });
        }

        if ((buttonRect.top > parent.getBoundingClientRect().bottom || buttonRect.bottom < parent.getBoundingClientRect().top) && event === 'mousewheel') {
          hideButton(item, closest);
        }
      }, {passive: true}),
    );
  }
});

const toggleFn = () => {
  const buttons = document.querySelectorAll('[data-toggle]');

  for (const button of buttons) {
    if (button.classList.contains('button-play')) {
      button.closest('.media-video')?.addEventListener('click', () => {
        const id = button.dataset.toggle;
        const elementToToggle = document.querySelector(`[data-toggle-element=${id}]`);

        elementToToggle.classList.toggle('toggled');
      });
    }
    else {
      button.addEventListener('click', () => {
        const id = button.dataset.toggle;
        const elementToToggle = document.querySelector(`[data-toggle-element=${id}]`);

        Object.values(document.querySelectorAll(`[data-toggle=${id}]`)).map(item => item.classList.toggle('active'));
        elementToToggle.classList.toggle('toggled');

        if (elementToToggle.classList.contains('video--box')) {
          const iframe = document.querySelector('.video--box iframe');
          const iframeSrc = iframe.src;

          iframe.setAttribute('src', iframeSrc);
        }
      });
    }
  }
};

const filterFn = () => {
  const filterButtons = document.querySelectorAll('[data-filter]');

  if (Object.values(filterButtons).length > 0) {
    Object.values(filterButtons).map(button => {
      Object.values(document.querySelectorAll(`[data-filter-element]`)).map((el, index) => {
        el.classList.remove('hidden');
        el.classList.remove('no--margin');

        if ((index + 1) % (window.ismobile ? 2 : 4) === 0) {
          el.classList.add('no--margin');
        }

      });
      button.addEventListener('click', () => {
        const filterValue = button.dataset.filter;
        const filterElements = document.querySelectorAll(`[data-filter-element]`);

        document.querySelector('[data-filter].active').classList.remove('active');
        button.classList.add('active');

        if (filterValue === 'all') {
          Object.values(filterElements).map((el, index) => {
            el.classList.remove('hidden');
            el.classList.remove('no--margin');

            if ((index + 1) % (window.ismobile ? 2 : 4) === 0) {
              el.classList.add('no--margin');
            }

          });
        }
        else {

          let index = 0;
          Object.values(filterElements).map(el => {
            el.classList.add('hidden');
            el.classList.remove('no--margin');

            if (el.dataset.filterElement.includes(filterValue)) {
              el.classList.remove('hidden');

              if ((index + 1) % (window.ismobile ? 2 : 4) === 0) {
                el.classList.add('no--margin');
              }

              index++;
            }
          });
        }
      });
    });
  }
};

const cursorMove = (cursor, x, y) => {
  gsap.set(cursor, {
    top: y - cursor.getBoundingClientRect().width / 2,
    left: x - cursor.getBoundingClientRect().width / 2,
  });
};

const splitText = document.querySelectorAll('.splitting');

//search highlight
function getParameterByName(name) {
  const match = RegExp('[?&]' + name + '=([^&]*)').exec(window.location.search);
  return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
}

function highlight(text, input) {
  let innerHTML = input.innerText;
  const innerHTMLTest = input.innerText.toLowerCase();

  const index = innerHTMLTest.indexOf(text);
  if (index >= 0) {
    innerHTML = innerHTML.substring(0, index) + '<span class=\'highlight--text\'>' + innerHTML.substring(index, index + text.length) + '</span>' + innerHTML.substring(index + text.length);
    input.innerHTML = innerHTML;
  }
}

const searchHighlight = () => {
  const search = getParameterByName('s');
  const inputs = document.querySelectorAll('.search--wrapper .post--text h2, .search--wrapper .post--text p');
  Object.values(inputs).map(input => {
    highlight(search, input);
  });
};

const imageFocalHandle = () => {
    const images = document.querySelectorAll('[data-focal]');

    [...images].map(image => {
        const y = image.dataset.focal.split(';')[1];

        gsap.set(image.querySelector('img'), {
            y: `${50 - (parseFloat(y) * 100)}%`,
            opacity: 1
        })
    })
}

window.addEventListener('DOMContentLoaded', () => {
  toggleFn();
  filterFn();
  scrollSectionClick();
  searchHighlight();
  imageFocalHandle();

  //if (!window.ismobile) cardsSize()

  const lastNavigations = document.querySelectorAll('.navigation--last');
  const hasChildrenItem = document.querySelectorAll('.menu-item-has-children');

  if (!window.ismobile) {
    document.documentElement.style.setProperty('--navHeight', '100%');

    Object.values(hasChildrenItem).map(item => {
      item.addEventListener('mouseenter', () => {
        const lastNav = item.querySelector('.navigation--last');
        if (lastNav) {
          const navigationItem = lastNav.closest('li');
          const h = navigationItem.getBoundingClientRect().height;

          document.documentElement.style.setProperty('--navHeight', h + navigationItem.querySelector('ul').getBoundingClientRect().height + 'px');
        }
        else {
          document.documentElement.style.setProperty('--navHeight', '100%');
        }
      });
    });

    Object.values(lastNavigations).map(nav => {
      const navigationItem = nav.closest('li');

      navigationItem.closest('.navigation').addEventListener('mouseenter', () => {
        gsap.set(lastNavigations, {
          top: (60 * 100 / window.innerWidth) + 'vw',
        });
      });
    });
  }

  Object.values(splitText).map(item => {
    Splitting({target: item, by: 'chars', whitespace: true});

    gsap.to(splitText, .3, {
      opacity: 1,
      delay: .5,
    });
    const chars = item.querySelectorAll('.word, .whitespace');
    Object.values(chars).map((el, index) => el.setAttribute('data-index', JSON.stringify(index)));
  });
});

window.addEventListener('scroll', (e) => {

  scrollSection();

  if (titleTriangle) {
    gsap.to(titleTriangle, .3, {
      y: -(window.scrollY * .5),
    });
  }

  // Darken animation
  const darkenElement = document.querySelectorAll('.darken--scroll');
  Object.values(darkenElement).map((element, index) => {
    if (element.getBoundingClientRect().top > window.innerHeight * .2 || element.getBoundingClientRect().bottom < window.innerHeight * .2) {
      if (element.classList.contains('active')) {
        document.body.classList.remove('dark');
        element.classList.remove('active');
      }
    }
    else if (element.getBoundingClientRect().top < window.innerHeight * .5) {
      if (!element.classList.contains('active')) {
        document.body.classList.add('dark');
        element.classList.add('active');
      }
    }
  });

  if (darkPage && window.offsetY === 0) {
    darkenElement[0].classList.add('active');
    document.body.classList.add('active');
  }

  const st = window.pageYOffset || document.documentElement.scrollTop;

  if (st > lastScrollTop) {
    // downscroll code
    window.scrollDirection = 'down';
  }
  else {
    // upscroll code
    window.scrollDirection = 'up';
  }

  if (mainHeader) {
    if (!mainHeader.querySelector('ul.toggled')) {
      gsap.to(mainHeader, .4, {
        y: window.scrollDirection === 'down' ? -(mainHeader.getBoundingClientRect().height)
          : (!document.body.classList.contains('hide--header') ? 0 : -(mainHeader.getBoundingClientRect().height)),
      });
    }

    if (window.scrollY > 30) {
      mainHeader.classList.add('header--background');
    }
    else {
      mainHeader.classList.remove('header--background');
    }
  }

  const navHistory = document.querySelector('.navigation--history');
  const breadcrumb = document.querySelector('.breadcrumb');
  if (window.ismobile) {
    mainHeader.querySelector('.navigation > ul').setAttribute('data-toggle-element', 'menu');

    if (navHistory) {
      const rect = navHistory.getBoundingClientRect();
      const breadcrumbRect = breadcrumb.getBoundingClientRect();
      const breadcrumbTop = parseFloat(getComputedStyle(breadcrumb).marginTop.replace('px', ''));
      if (breadcrumbRect.top <= window.outerHeight) {
        navHistory.classList.add('stuck');
      }
      else {
        navHistory.classList.remove('stuck');
      }
    }
  }

  lastScrollTop = st <= 0 ? 0 : st;

}, {passive: true});

window.addEventListener('mousemove', (e) => {
  mouse.x = e.clientX;
  mouse.y = e.clientY;
}, {passive: true});

window.addEventListener('resize', () => {
  //if (!window.ismobile) cardsSize()
}, {passive: true});
