export default function lazyLoad(force) {
    const images = document.querySelectorAll('img, iframe');

    Object.values(images).map(image => {
        const rect = image.getBoundingClientRect();

        if (image.getAttribute('data-src') || image.getAttribute('data-old-lazy-src') || image.getAttribute('data-lazy-src')) {
            if (rect.top < window.innerHeight) {
                showImage(image, force);
            }
        }
    });
};

const showImage = (image) => {
    const sources = image.closest('picture')?.querySelectorAll('source');
    if (sources) {
        Object.values(sources).map(source => {
            const dataSrcSet = source.getAttribute('data-srcSet')
            if (dataSrcSet) {
                source.setAttribute('srcset', dataSrcSet);
                source.removeAttribute('data-srcset');
            }

            return true;
        })
    }

    if (image.getAttribute('data-src') || image.getAttribute('data-old-lazy-src') || image.getAttribute('data-lazy-src')) {
        image.src = image.getAttribute('data-src') || image.getAttribute('data-old-lazy-src') || image.getAttribute('data-lazy-src');
        image.removeAttribute('data-src');
    }
}
