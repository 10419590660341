import gsap from "gsap";

import Swiper, {Navigation, Pagination, Mousewheel} from "swiper";
import lazyLoad from './lazyLoad';

Swiper.use([Navigation, Pagination, Mousewheel]);

let mouse = {};

// Circle Animation
const circleAnimation = () => {
    const circles = document.querySelectorAll('.circle--svg');

    Object.values(circles).map((circle, index) => {
        const miniCircles = circle.querySelectorAll('path');

        if (!circle.classList.contains('animated')) {
            gsap.set(miniCircles, {
                rotate: -90,
                scale: 0
            });

            const circleRect = circle.getBoundingClientRect();

            if (circleRect.top + circleRect.height <= window.innerHeight) {
                circle.classList.add('animated');

                gsap.to(miniCircles, 1, {
                    opacity: 1,
                    rotate: 0,
                    scale: 1
                })

            }
        }
    })
};

//Ecomode

const ecoMode = ()  => {
  const ecoButton = document.getElementById('EcoButton');
  const imagesLazy = document.querySelectorAll('img:not([data-skip-lazy]):not(.no-eco), iframe[data-rocket-lazyload]:not(.no-eco)');
  const imagesSources = document.querySelectorAll('figure:not(.no-eco) source');

  const ecomode = localStorage.getItem('ecomode') === 'true';
  ecoButton.checked = ecomode;

  if (ecomode) {
    document.body.classList.add('eco');
  }

  Object.values(imagesSources).map(item => {
    item.dataset.type = item.type;
    item.type = ecomode ? 'none' : item.dataset.type
  })

  Object.values(imagesLazy).map(item => {
    const itemWidth = parseFloat(item.getAttribute('width'))
    const itemHeight = parseFloat(item.getAttribute('height'))

    const fallback = `data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%20${itemWidth || 1}%20${itemHeight || 1}'%3E%3C/svg%3E`;

    const oldSrc = item.dataset.lazySrc || item.src;
    item.dataset.oldLazySrc = oldSrc;
    item.dataset.lazySrc = ecomode ? fallback : oldSrc;
  });

  ecoButton.addEventListener('change', e => {
    const ecomodeNew = e.target.checked;
    if (ecomodeNew) document.body.classList.add('eco');
    else document.body.classList.remove('eco');

    localStorage.setItem('ecomode', ecomodeNew);

    Object.values(imagesSources).map(item => {
      item.type = ecomodeNew ? 'none' : item.dataset.type
    })

    Object.values(imagesLazy).map(item => {
      const itemWidth = parseFloat(item.getAttribute('width'))
      const itemHeight = parseFloat(item.getAttribute('height'))
      const fallback = `data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%20${itemWidth || 1}%20${itemHeight || 1}'%3E%3C/svg%3E`;
      const oldSrc = item.dataset.lazySrc || item.src;


      item.dataset.lazySrc = ecomodeNew ? fallback : oldSrc;
      item.src = ecomodeNew ? fallback : oldSrc;

      if (ecomodeNew) {
        gsap.set(item, {
          height: item.getBoundingClientRect().height
        })
      } else {
        lazyLoad(true);
      }
    });
  });
}

const darkPage = document.body.classList.contains('dark');
const mainHeader = document.querySelector('header.main-header');

ecoMode();

window.addEventListener('DOMContentLoaded', () => {
    const pageTitle = document.querySelector('.page-title');
    if (pageTitle) {
        gsap.set(pageTitle, {
            y: 30,
            onComplete: () => {
                gsap.to(pageTitle, .8, {
                    opacity: 1,
                    y: 0,
                    delay: .5
                })
            }
        })
    }

    circleAnimation();
    initSlider();

    if (darkPage) {
        document.getElementsByClassName('section')[0].classList.add('darken--scroll');
        document.getElementsByClassName('section')[0].classList.add('active');
    }

    const navigationItem = document.querySelectorAll('.navigation > ul > li');
    const navigation = document.querySelector('.navigation > ul');

    if (navigationItem.length > 0) {
        Object.values(navigationItem).map(item => {
            item.addEventListener('mouseenter', () => {
                gsap.set(mainHeader, {
                    backgroundColor: getComputedStyle(document.body).backgroundColor,
                    borderColor: getComputedStyle(document.body).backgroundColor
                })
            });
        });

        navigation.addEventListener('mouseleave', () => {
            gsap.set(mainHeader, {
                clearProps: 'background-color,border-color'
            })
        });
    }


});

const cursorMove = (cursor, x, y) => {
    gsap.set(cursor, {
        top: y - cursor.getBoundingClientRect().width / 2,
        left: x - cursor.getBoundingClientRect().width / 2
    })
};


const initSlider = () => {
    const sliders = document.querySelectorAll('.swiper');

    Object.values(sliders).map(slider => {
        const s = new Swiper(slider, {
            loop: slider.dataset.loop === 'true',
            slidesPerView: 'auto',
            autoHeight: slider.dataset.autoHeight ? slider.dataset.autoHeight === 'true' : window.ismobile,
            navigation: {
                nextEl: slider.dataset.navNext || null,
                prevEl: slider.dataset.navPrev || null,
            },
            pagination: slider.dataset.pagination ? {
                el: slider.dataset.pagination,
                type: 'fraction',
            } : false,
            mousewheel: slider.dataset.mousewheel === 'true' ? {
                releaseOnEdges: false
            } : false,
        });

        if (slider.dataset.dragElement) {

            const dragEl = document.querySelector(slider.dataset.dragElement)
            slider.addEventListener('mousemove', (e) => {
                cursorMove(dragEl, e.clientX, e.clientY)
            });

            slider.addEventListener('mouseenter', () => {
                gsap.set(dragEl, {
                    display: 'flex',
                    position: 'fixed',
                    onComplete: () => {
                        gsap.to(dragEl, .3, {
                            opacity: 1
                        })
                    }
                })
            });

            slider.addEventListener('mouseleave', () => {
                gsap.to(dragEl, .3, {
                    opacity: 0,
                    onComplete: () => {
                        gsap.set(dragEl, {
                            display: 'none',
                            position: "absolute"
                        })
                    }
                })

            });

            s.on('touchMove', (e) => {
                cursorMove(dragEl, e.touches.currentX, e.touches.currentY - window.scrollY)
            });
        }

        const pagination = document.querySelector('.swiper-pagination')

        if (slider.dataset.active) {
            const indexTo = parseFloat(slider.dataset.active);
            s.slideTo(indexTo - 1);

            if (pagination) pagination.querySelector('span').innerText = `${indexTo < 10 ? '0' : ''}${JSON.stringify(indexTo)}`;

            gsap.to(slider, .3, {
                opacity: 1,
                delay: 1
            });

            s.on('slideChange', (e) => {
                // Current URL: https://my-website.com/page_a
                const nextURL = e.slides[e.realIndex].dataset.url;
                const nextTitle = e.slides[e.realIndex].dataset.pageTitle;
                const nextName = e.slides[e.realIndex].dataset.name;
                const nextState = {};

                // This will create a new entry in the browser's history, without reloading
                window.history.pushState(nextState, nextTitle, nextURL);

                // This will replace the current entry in the browser's history, without reloading
                window.history.replaceState(nextState, nextTitle, nextURL);

                document.title = nextTitle;

                const lastBreadcrumb = document.querySelector('.breadcrumb_last');

                if (lastBreadcrumb) lastBreadcrumb.innerText = nextName;

                const slideNumber = e.realIndex + 1;
                if (pagination) pagination.querySelector('span').innerText = `${slideNumber < 10 ? '0' : ''}${JSON.stringify(slideNumber)}`
            });
        }
    })
};

window.addEventListener('scroll', (e) => {
    circleAnimation();
});
